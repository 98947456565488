import React from "react";


export default function Header()
{
    return(
        <nav className="container-fluid">
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className="nav-link active" href="#">Elementary Algebra</a>
                </li>
            </ul>
        </nav>
    )
}